@import "../../style.scss";

.comments{

    @include themify($themes){
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }

        .write{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0px;
            gap: 20px;

            input{
                flex:5;
                padding: 10px;
                border: 1px solid themed("border");
                background-color: transparent;
                color: themed("textColor");
            };

            button{
                border: none;
                background-color: #5271ff;
                color: white;
                padding: 10px 20px;
                cursor: pointer;
                border-radius: 3px;
            }
        }

        .comment{
            display: flex;
            gap: 20px;
            margin: 30px 0px;
            justify-content: space-between;

            .comment_details{
                flex: 5;
                display: flex;
                flex-direction: column;
                gap:3px;
                align-items: flex-start;

                span{
                    font-weight: 500;
                }

                p{
                    color :themed("textColorSoft");
                    // align-items: flex-start;
                }
            }

            .comment_date{
               flex: 1;
               align-self: center; 
               color: gray;
               font-size: 12px;
            }
        }
    }

}