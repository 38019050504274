@import "../../style.scss";

.home{
    @include themify($themes) {
        background-color: themed("bgSoft");
        padding: 20px 70px;  
        min-height: 100vh;  
        
        @include mobile{
            padding: 10px;
          }

          @include mobile{
            padding: 20px;
          }
    }
}