@import "../../style.scss";

.profile {
  @include themify($themes) {
    background-color: themed("bgSoft");
    .images {
      width: 100%;
      height: 300px;
      position: relative;

      .cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .dp {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 200px;
      }
    }

    .profileContainer {
      padding: 20px  70px;
      
      @include mobile{
          padding: 10px;
      }

      @include tablet{
          padding: 20px;
      }

      .uInfo {
        height: 180px;
        -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        border-radius: 20px;
        background-color: themed("bg");
        color: themed("textColor");
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @include mobile{
            flex-direction: column;
            height: 30vh;
            padding :20px;
            margin: 100px;
          }

        .left {
          flex: 1;
          display: flex;
          gap: 10px;

          @include tablet{
            flex-wrap: wrap;
        }

          a {
            color: themed("textColorSoft");
          }
        }
        .center {
          flex: 1;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 10px;
          span {
            font-size: 30px;
            font-weight: 500;
            //shift the text upward
            // transform: translateY(-10px);

          }
          .info {
            // background-color: aqua;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // gap: 10px;
            .item {
              display: flex;
              align-items: center;
              gap: 5px;
              color: themed("textColorSoft");
              span {
                font-size: 12px;
                // transform: translateY(0px);
              }
            }
          }
          button {
            border: none;
            background-color: #5271ff;
            color: white;
            padding: 10px  20px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
        }
      }
    }
  }
}