@import "../../style.scss";

.leftBar {
  @include themify($themes) {
    flex: 2;
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    
    background-color: themed("bg");
    color: themed("textColor");

    @include mobile{
      display: none;
    }

    @include tablet{
      display: none;
    }

    .container {
      padding: 20px;

      hr {
        margin: 20px 0;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }
      .menu {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .user {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            font-size: 14px;
          }
        }

        .items {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 30px;
            height: 30px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
