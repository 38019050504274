$themes: (
    light: (
      textColor: #000,
      bg: white,
      logo: darkblue,
      bgSoft: #eee,
      textColorSoft: #666,
      border: lightgrey,
    ),
    dark: (
      textColor: whitesmoke,
      bg: #222,
      logo: white,
      bgSoft: #333,
      textColorSoft: lightgrey,
      border: #444,
    ),
  );


  @mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin mobile{
  @media(max-width: 480px){
    @content;
  }
}

@mixin tablet{
  @media(max-width: 960px){
    @content;
  }
}