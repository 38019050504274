@import "../../style.scss";

.rightBar {
  @include themify($themes) {
    flex: 3;
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    background-color: themed("bgSoft");

    @include mobile{
      display: none;
    }

    @include tablet{
      display: none;
    }

    .container {
      padding: 20px;
      .item {
        -webkit-box-shadow: 0px 0px 15px 1px rgba(56, 55, 55, 0.09);
        -moz-box-shadow: 0px 0px 15px 1px rgba(56, 55, 55, 0.09);
        box-shadow: 0px 0px 15px 1px rgba(56, 55, 55, 0.09);
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        background-color: themed("bg");
        span {
          color: gray;
        }
        .user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0px;
          .userInfo {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }

            .online {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: rgb(98, 241, 90);
              position: absolute;
              top: 0px;
              left: 30px;
            }

            p {
              color: themed("textColorSoft");
            }

            span {
              font-weight: 500;
              // font-size: 14px;
              color: black;
              color: themed("textColor");
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            gap: 10px;
            button {
              border: none;
              color: white;
              padding: 5px;
              cursor: pointer;
              border-radius: 5px;

              &:first-child {
                background-color: #5271ff;
              }
              &:last-child {
                background-color: #f0554f;
              }
            }
          }
        }
      }
    }
  }
}
